@font-face {
  font-family: 'icons';
  src: url(/assets/icons-0cd226a9f41e31b0546db7c183aaea46.woff2) format('woff2'),
       url(/assets/icons-ffb40ab1e34814af878cd220d420655e.woff) format('woff');
  font-weight: normal; font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "icons";
  speak: never;
  opacity: .8;
  text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);

  display: inline-block;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  line-height: 1em;

  font-style: normal !important;
  font-weight: normal !important;
}

.icon-only:before { margin-right: 0; }
.icon-empty:before { content: ''; }
